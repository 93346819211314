import { PhaseColor, Sport } from '@hulanbv/toftennis';

export const phaseColorLabels: Record<
  Sport,
  Partial<Record<PhaseColor, string>>
> = {
  [Sport.TENNIS]: {
    [PhaseColor.GREEN]: 'Groen',
    [PhaseColor.ORANGE]: 'Oranje',
    [PhaseColor.RED]: 'Rood',
  },
  [Sport.PADEL]: {
    [PhaseColor.GREEN]: 'Kaart 1',
    [PhaseColor.ORANGE]: 'Kaart 2',
    [PhaseColor.RED]: 'Kaart 3',
  },
  [Sport.WHEELCHAIR_TENNIS]: {
    [PhaseColor.GREEN]: 'Groen',
    [PhaseColor.ORANGE]: 'Oranje',
  },
};
