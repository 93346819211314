import { marked } from 'marked';
import { useState } from 'react';
import { IPhase } from '@hulanbv/toftennis';
import { useModalContext } from '../../domain/common/hooks/modal/use-modal-context.hook';
import { ButtonElement } from '../elements/button-element/button-element.component';
import { FlexElement } from '../elements/flex-element/flex-element.component';
import { ReactComponent as ArrowLeftSvg } from '../../assets/graphics/arrow-left-icon.svg';
import { ReactComponent as ArrowRightSvg } from '../../assets/graphics/arrow-right-icon.svg';
import { VideoPlayer } from '../elements/video-player-element/video-player.element.component';

type Props = {
  phase: IPhase;
  defaultLevel?: number;
};

function PhaseModalContentTemplate(props: Props): JSX.Element {
  const { closeModal } = useModalContext();
  const [levelIndex, setLevelIndex] = useState(
    props.phase.phaseLevels?.findIndex(
      (phaseLevel) => phaseLevel.level === props.defaultLevel,
    ) ?? 0,
  );
  const selectedPhaseLevel = props.phase.phaseLevels?.[levelIndex];

  return (
    <FlexElement alignItems="flex-start" gap={30}>
      <FlexElement alignItems="flex-start" gap={10}>
        <h2>{props.phase.title}</h2>
        <p>{props.phase.skill}</p>
      </FlexElement>
      <FlexElement alignItems="flex-start">
        <FlexElement
          alignItems="flex-start"
          attributes={{
            dangerouslySetInnerHTML: {
              // eslint-disable-next-line @typescript-eslint/naming-convention -- Component property
              __html: marked(selectedPhaseLevel?.requirements ?? ''),
            },
          }}
        />
        {selectedPhaseLevel?.videoUrl && (
          <VideoPlayer url={selectedPhaseLevel?.videoUrl} width={'100%'} />
        )}
      </FlexElement>
      <FlexElement direction="row" justifyContent="space-between">
        <ButtonElement
          fitContent
          attributes={{
            onClick: () => setLevelIndex(levelIndex - 1),
            disabled: levelIndex <= 0,
          }}
        >
          <ArrowLeftSvg />
        </ButtonElement>
        <p>Level {selectedPhaseLevel?.level}</p>
        <ButtonElement
          fitContent
          attributes={{
            onClick: () => setLevelIndex(levelIndex + 1),
            disabled: levelIndex >= (props.phase.phaseLevels?.length ?? 1) - 1,
          }}
        >
          <ArrowRightSvg />
        </ButtonElement>
      </FlexElement>
      <ButtonElement flavour="blue" attributes={{ onClick: closeModal }}>
        Sluiten
      </ButtonElement>
    </FlexElement>
  );
}

export { PhaseModalContentTemplate };
