import { IPhase, IPhaseLevel, PhaseMastery } from '@hulanbv/toftennis';
import { Fragment } from 'react';
import { useModalContext } from '../../../../domain/common/hooks/modal/use-modal-context.hook';
import { join } from '../../../../domain/common/utilities/join.utility';
import { ConfirmModalTemplate } from '../../../templates/confirm-modal-template.component';
import styles from './swirl-slice-element.module.css';
import { phaseColorLevelBackgroundColors } from '../../../../domain/user-phases/constants/phase-color-level-background-colors.constant';
import { sportBallGraphics } from '../../../../domain/common/constants/sport-ball-graphics.constant';

type Props = {
  phase: IPhase;
  currentLevel?: number;
  currentMastery?: PhaseMastery;
  onLevelChange?: (
    newLevel: number,
    newMastery: PhaseMastery,
  ) => void | Promise<void>;
};

function SwirlSliceElement(props: Props): JSX.Element {
  const { openModal } = useModalContext();

  const handleLevelClick = async (clickedIndex: number) => {
    let newIndex = clickedIndex;
    const currentIndex =
      props.phase.phaseLevels?.findIndex(
        (level) => level.level === props.currentLevel,
      ) ?? null;
    let newMastery = PhaseMastery.MASTERED;

    if (currentIndex === newIndex) {
      if (props.currentMastery === PhaseMastery.MASTERED) {
        newIndex -= 1;
      }
      newMastery = PhaseMastery.MASTERED;
    }
    const newPhaseLevel = props.phase.phaseLevels?.[Math.max(0, newIndex)];
    if (newIndex === -1) {
      newMastery = PhaseMastery.UNTREATED;
    }
    if (!newPhaseLevel) {
      return;
    }

    if (currentIndex !== null && currentIndex > newIndex) {
      const isConfirmed = await openModal<boolean>((resolve) => (
        <ConfirmModalTemplate
          resolve={resolve}
          content={
            <p>
              Wil je de geselecteerde gebruiker(s) terugzetten naar niveau{' '}
              <strong>{newPhaseLevel.level}</strong> van{' '}
              <strong>{props.phase.skill}</strong>
            </p>
          }
        />
      ));

      if (!isConfirmed) {
        return;
      }
    }

    await props.onLevelChange?.(newPhaseLevel.level, newMastery);
  };

  return (
    <Fragment>
      <div className={styles.levels}>
        {props.phase.phaseLevels
          ?.slice(0, 3)
          ?.map((phaseLevel: IPhaseLevel, index: number) => (
            <div
              key={phaseLevel.id}
              className={join(
                styles.level,
                phaseLevel.level <= (props.currentLevel ?? 0) &&
                  styles.achieved,
                phaseLevel.level === props.currentLevel &&
                  props.currentMastery === PhaseMastery.TREATED &&
                  styles.treated,
              )}
              style={{
                backgroundImage:
                  phaseLevel.level <= (props.currentLevel ?? 0)
                    ? `url(${sportBallGraphics[props.phase.sport]})`
                    : undefined,
                backgroundColor:
                  phaseColorLevelBackgroundColors[props.phase.sport][
                    props.phase.color
                  ]?.[1],
              }}
              onClick={() => handleLevelClick(index)}
            />
          ))}
      </div>
      <p className={styles.name}>{props.phase.skill}</p>
      <h1 className={styles.identifier}>{props.phase.phaseIndex + 1}</h1>
    </Fragment>
  );
}

export { SwirlSliceElement };
