import { IPhase, PhaseMastery } from '@hulanbv/toftennis';
import { HTMLAttributes, useCallback } from 'react';
import { FlexElement } from '../flex-element/flex-element.component';
import { ReactComponent as PlayIcon } from '../../../assets/graphics/play-icon.svg';
import { join } from '../../../domain/common/utilities/join.utility';
import styles from './user-phase-list-item-element.module.css';
import { UserPhaseLevelSelector } from '../user-phase-level-selector-element/user-phase-level-selector-element.component';
import { useModalContext } from '../../../domain/common/hooks/modal/use-modal-context.hook';
import { PhaseModalContentTemplate } from '../../templates/phase-modal-content-template';
import { phaseColorLevelBackgroundColors } from '../../../domain/user-phases/constants/phase-color-level-background-colors.constant';
import { phaseColorLevelFontColors } from '../../../domain/user-phases/constants/phase-color-level-font-colors.constant';

type Props = {
  attributes?: HTMLAttributes<HTMLDivElement>;

  onLevelChange: (
    newLevel: number,
    mastery: PhaseMastery,
  ) => void | Promise<void>;

  phase: IPhase;
  level?: number;
  mastery?: number;
};

function UserPhaseListItemElement(props: Props): JSX.Element {
  const { openModal } = useModalContext();
  const backgroundColor =
    phaseColorLevelBackgroundColors[props.phase.sport][props.phase.color]?.[
      (props.level ?? 0) - 1
    ];
  const color =
    phaseColorLevelFontColors[props.phase.sport][props.phase.color]?.[
      (props.level ?? 0) - 1
    ] ?? 'var(--brand-text-dark)';

  const openPhaseModal = useCallback(() => {
    const nextLevel =
      props.phase.phaseLevels?.find(
        ({ level }) =>
          level >=
          (props.level ?? 0) +
            (props.mastery === PhaseMastery.MASTERED ? 1 : 0),
      ) ?? props.phase.phaseLevels?.at(-1);
    openModal(() => (
      <PhaseModalContentTemplate
        phase={props.phase}
        defaultLevel={nextLevel?.level}
      />
    ));
  }, [openModal, props.level, props.mastery, props.phase]);

  return (
    <FlexElement
      gap={30}
      attributes={{
        ...props.attributes,
        className: join(props.attributes?.className, styles.listItem),
        style: {
          ...props.attributes?.style,
          backgroundColor,
          color,
        },
      }}
    >
      <FlexElement
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <FlexElement alignItems="flex-start" gap={10} flex={1}>
          <h2>
            Fase {props.phase.phaseIndex + 1} - {props.phase.title}
          </h2>
          <p>{props.phase.skill}</p>
        </FlexElement>
        <PlayIcon
          width={25}
          height={25}
          fill={color}
          onClick={openPhaseModal}
        />
      </FlexElement>

      <FlexElement direction="row">
        <UserPhaseLevelSelector
          phase={props.phase}
          currentLevel={props.level}
          currentMastery={props.mastery}
          onLevelChange={props.onLevelChange}
        />
      </FlexElement>
    </FlexElement>
  );
}

export { UserPhaseListItemElement };
