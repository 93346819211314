import { PhaseColor, PhaseMastery, IPhase, Sport } from '@hulanbv/toftennis';
import {
  CSSProperties,
  HTMLAttributes,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { swirlBackgrounds } from '../../../domain/common/constants/swirl-backgrounds.constant';
import { useAnimationFrame } from '../../../domain/common/hooks/use-animation-frame';
import { join } from '../../../domain/common/utilities/join.utility';
import { ReactComponent as PlayIcon } from '../../../assets/graphics/play-icon.svg';
import styles from './swirl-element.module.css';
import { SwirlSliceElement } from './swirl-slice-element/swirl-slice-element.component';
import { PhaseModalContentTemplate } from '../../templates/phase-modal-content-template';
import { FlexElement } from '../flex-element/flex-element.component';
import { useModalContext } from '../../../domain/common/hooks/modal/use-modal-context.hook';

const swirlFriction = 1;
const swirlMomentum = 1;
const swirlGrip = 60;

type Props = {
  attributes?: HTMLAttributes<HTMLDivElement>;
  /**
   * The color of the phases.
   */
  color: PhaseColor;

  sport: Sport;
  /**
   * The phases which need to be shown
   */
  phases: IPhase[];
  /**
   * Array of maximum acquired level with mastery at phaseIndex
   * */
  achievedPhaseLevels?: [number, PhaseMastery][];

  /**
   * Callback for whenever the level of a phase is changed.
   */
  onLevelChange: (
    newLevel: number,
    newMastery: PhaseMastery,
    phase: IPhase,
  ) => void | Promise<void>;
};

/**
 * The Swirl Element which can be spun around.
 * @returns The Swirl Element.
 */
function SwirlElement(props: Props): JSX.Element {
  const swirlElement = useRef<HTMLDivElement>(null);
  const swirlRotationDegrees = useRef(0);
  const swirlVelocity = useRef(0);
  const isDraggingSwirl = useRef(false);
  const [highlightedPhaseIndex, setHighlightedPhaseIndex] = useState(0);
  const { openModal } = useModalContext();
  const elementRef = useRef<HTMLDivElement>(null);
  const [maxWidth, setMaxWidth] = useState<number>();
  const maxWidthStyles = useMemo<CSSProperties>(
    () => ({ maxWidth }),
    [maxWidth],
  );

  /**
   * the last touch client Y position stores the last touch position used for
   * calculating the swirl velocity. When the next touch client Y position has
   * been consumed, the position will be stored here.
   */
  const lastTouchClientY = useRef(0);

  /**
   * The next touch client Y position stores the most recent touch move event's
   * client Y position. This number is stored here for the animation from to
   * pick it up in order to calculate the velocity based on the change in
   * position.
   */
  const nextTouchClientY = useRef(0);

  /**
   * Event handler for the touch start event, this marks the start of the swirl
   * being dragged and stores the client Y position of the touch in the last
   * and next touch client Y positions.
   */
  const handleTouchStart = useCallback((touchEvent: TouchEvent) => {
    lastTouchClientY.current = touchEvent.touches[0]?.clientY ?? 0;
    // We're also going to assign the touch Y value to the next touch Y value
    // in order to prevent jumping when the user starts dragging.
    nextTouchClientY.current = lastTouchClientY.current;
    isDraggingSwirl.current = true;
  }, []);

  /**
   * Event handler for the touch end event, this marks the end of the swirl
   * being dragged.
   */
  const handleTouchEnd = useCallback(() => {
    isDraggingSwirl.current = false;
  }, []);

  /**
   * Event handler for the touch move event.
   */
  const handleTouchMove = useCallback((touchEvent: TouchEvent) => {
    if (isDraggingSwirl.current === true) {
      // If the swirl was being dragged, the touch event's client Y position is
      // stored in the nextTouchClientY variable.
      nextTouchClientY.current = touchEvent.touches[0]?.clientY ?? 0;
    }
  }, []);

  // Effect invoked every frame to update the Swirl's rotation and velocity.
  useAnimationFrame((deltaTime) => {
    // The next touch client Y position which is stored by the touch move
    // event handler will be used against the last touch client Y position to
    // calculate the delta of the touch Y.
    const deltaClientY = nextTouchClientY.current - lastTouchClientY.current;
    // The last touch client Y position is updated to the next touch client Y.
    lastTouchClientY.current = nextTouchClientY.current;
    // The swirl velocity is updated based on the delta of the touch Y and will
    // be multiplied by the delta time times the swirl grip. By multipling it
    // with the delta time, the velocity will be scaled by the number of frames.
    swirlVelocity.current += deltaClientY * (deltaTime * swirlGrip);
    // When the swirl's velocity is greater than a given threshold, the swirl's
    // rotation degrees will be calculated and its style will be updated.
    if (swirlVelocity.current > 1 || swirlVelocity.current < -1) {
      // The swirls height will be used to calculate a correction on the
      // strength of the swirl's momentum delta, so a smaller swirl will need
      // less momentum in order to spin.
      // TODO -- cache this value.
      const swirlHeight = swirlElement.current?.offsetHeight ?? 0;
      const swirlHeightCorrection = swirlHeight / 500;
      // The Swirl's rotation degrees delta will be calculated to determine how
      // much the swirl will spin.
      const swirlRotationDegreesDelta =
        swirlVelocity.current * (swirlMomentum / swirlHeightCorrection);
      // The Swirl's velocity delta will be calculated to determine how much
      // the swirl speed to change over time.
      let swirlVelocityDelta = swirlVelocity.current * swirlFriction * -1;
      const isDraggingSwirlWithLowDelta =
        isDraggingSwirl.current === true &&
        (deltaClientY < 2 || deltaClientY > -2);
      // If the swirl is being dragged and the delta of the touch Y is small,
      // the swirl's velocity will be incremented by a factor.
      if (isDraggingSwirlWithLowDelta === true) {
        swirlVelocityDelta *= 5;
      }
      // Appling the delta calculations to the swirl's rotation and velocity.
      swirlRotationDegrees.current += swirlRotationDegreesDelta * deltaTime;
      swirlVelocity.current += swirlVelocityDelta * deltaTime;
      // Clamping the rotation degrees within the range of a full rotation in
      // order to prevent the value from going out of bounds.
      if (swirlRotationDegrees.current > 360) {
        swirlRotationDegrees.current -= 360;
      } else if (swirlRotationDegrees.current < 0) {
        swirlRotationDegrees.current += 360;
      }
      // The updates swirl rotation degrees will be applied to the swirl element
      // using its transform rotation style property.
      if (swirlElement.current !== null) {
        const styleTransform = `rotate(${swirlRotationDegrees.current}deg)`;
        swirlElement.current.style.transform = styleTransform;
      }
      // The highlighted phase index will be updated based on the swirl's
      // rotation degrees. This marks the phase which is currently being
      // the most right of the swirl (at 90 degrees visually, 0 degrees
      // mathematically).
      let nextHighlightedPhaseIndex = Math.round(
        10 - swirlRotationDegrees.current / 36,
      );
      if (nextHighlightedPhaseIndex === 10) {
        nextHighlightedPhaseIndex = 0;
      }
      // If the highlighted phase index is different than the current phase
      // index stored in the stat, the phase index state will be updated and the
      // phase will be highlighted.
      if (nextHighlightedPhaseIndex !== highlightedPhaseIndex) {
        setHighlightedPhaseIndex(nextHighlightedPhaseIndex);
      }
    }
  });

  /**
   * Event handler for the resize event
   */
  const handleResize = useCallback(() => {
    if (!elementRef.current) {
      return;
    }

    // sets the max width of the swirl and ticker components equal to 60% height
    // this is done because mozilla and safari can't maintain aspect-ratio
    // when the width surpasses the max-height style
    setMaxWidth(elementRef.current.clientHeight * 0.6);
  }, []);

  useEffect(() => {
    handleResize();
  }, [handleResize]);

  // Effect which will bind and unbind the event listeners for the Swirl's
  // and window's touch events.
  useEffect(() => {
    const target = swirlElement.current;
    target?.addEventListener('touchstart', handleTouchStart);
    window.addEventListener('touchmove', handleTouchMove);
    window.addEventListener('touchend', handleTouchEnd);
    window.addEventListener('resize', handleResize);

    return () => {
      target?.removeEventListener('touchstart', handleTouchStart);
      window.removeEventListener('touchmove', handleTouchMove);
      window.removeEventListener('touchend', handleTouchEnd);
      window.removeEventListener('resize', handleResize);
    };
  }, [
    swirlElement,
    handleTouchStart,
    handleTouchMove,
    handleTouchEnd,
    handleResize,
  ]);

  const openPhaseModal = useCallback(() => {
    const highlightedPhase = props.phases[highlightedPhaseIndex];
    const highlightedPhaseLevel =
      props.achievedPhaseLevels?.[highlightedPhaseIndex];
    if (!highlightedPhase) {
      return;
    }
    const nextLevel =
      highlightedPhase.phaseLevels?.find(
        ({ level }) =>
          level >=
          (highlightedPhaseLevel?.[0] ?? 0) +
            (highlightedPhaseLevel?.[1] === PhaseMastery.MASTERED ? 1 : 0),
      ) ?? highlightedPhase.phaseLevels?.at(-1);

    openModal(() => (
      <PhaseModalContentTemplate
        phase={highlightedPhase}
        defaultLevel={nextLevel?.level}
      />
    ));
  }, [
    highlightedPhaseIndex,
    openModal,
    props.achievedPhaseLevels,
    props.phases,
  ]);

  // Renders the Swirl.
  return (
    <div
      {...{
        ...props.attributes,
        className: join(styles.element, props.attributes?.className),
        ref: elementRef,
      }}
    >
      <div className={styles.phaseDetails} style={maxWidthStyles}>
        <FlexElement gap={5} direction="row">
          <PlayIcon width={30} height={30} onClick={openPhaseModal} />
          <FlexElement gap={0} alignItems="flex-end">
            <h1>
              Fase {(props.phases[highlightedPhaseIndex]?.phaseIndex ?? 0) + 1}
            </h1>
            <p>{props.phases[highlightedPhaseIndex]?.title}</p>
          </FlexElement>
        </FlexElement>
      </div>
      <div className={styles.ticker} style={maxWidthStyles}>
        <div className={styles.arrow} />
      </div>
      <div className={styles.container} style={maxWidthStyles}>
        <div
          ref={swirlElement}
          className={styles.swirl}
          style={{
            backgroundImage: `url(${
              swirlBackgrounds[props.sport][props.color]
            })`,
          }}
        >
          {props.phases.map((phase, index) => (
            <div
              key={phase.phaseIndex}
              className={styles.segment}
              style={{ transform: `rotate(${phase.phaseIndex * 36}deg)` }}
            >
              <SwirlSliceElement
                currentLevel={
                  props.achievedPhaseLevels?.[phase.phaseIndex]?.[0]
                }
                currentMastery={
                  props.achievedPhaseLevels?.[phase.phaseIndex]?.[1]
                }
                phase={phase}
                onLevelChange={(level, mastery) =>
                  props.onLevelChange(level, mastery, phase)
                }
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export { SwirlElement };
