import { IPhase, PhaseMastery } from '@hulanbv/toftennis';
import { HTMLAttributes } from 'react';
import { useModalContext } from '../../../domain/common/hooks/modal/use-modal-context.hook';
import { join } from '../../../domain/common/utilities/join.utility';
import { ConfirmModalTemplate } from '../../templates/confirm-modal-template.component';
import { FlexElement } from '../flex-element/flex-element.component';
import styles from './user-phase-level-selector-element.module.css';
import { sportBallGraphics } from '../../../domain/common/constants/sport-ball-graphics.constant';

type Props = {
  attributes?: HTMLAttributes<HTMLDivElement>;
  currentLevel?: number;
  currentMastery?: PhaseMastery;
  phase: IPhase;
  onLevelChange: (
    newLevel: number,
    newMastery: PhaseMastery,
  ) => void | Promise<void>;
};

function UserPhaseLevelSelector(props: Props): JSX.Element {
  const { openModal } = useModalContext();

  const handleLevelClick = async (clickedIndex: number) => {
    let newIndex = clickedIndex;
    const currentIndex =
      props.phase.phaseLevels?.findIndex(
        (level) => level.level === props.currentLevel,
      ) ?? null;
    let newMastery = PhaseMastery.MASTERED;

    if (currentIndex === newIndex) {
      if (props.currentMastery === PhaseMastery.MASTERED) {
        newIndex -= 1;
      }
      newMastery = PhaseMastery.MASTERED;
    }
    const newPhaseLevel = props.phase.phaseLevels?.[Math.max(0, newIndex)];
    if (newIndex === -1) {
      newMastery = PhaseMastery.UNTREATED;
    }
    if (!newPhaseLevel) {
      return;
    }

    if (currentIndex !== null && currentIndex > newIndex) {
      const isConfirmed = await openModal<boolean>((resolve) => (
        <ConfirmModalTemplate
          resolve={resolve}
          content={
            <p>
              Wil je de geselecteerde gebruiker(s) terugzetten naar niveau{' '}
              <strong>{newPhaseLevel.level}</strong> van{' '}
              <strong>{props.phase.skill}</strong>
            </p>
          }
        />
      ));

      if (!isConfirmed) {
        return;
      }
    }

    await props.onLevelChange?.(newPhaseLevel.level, newMastery);
  };

  return (
    <FlexElement attributes={props.attributes} direction="row" gap={25}>
      {props.phase.phaseLevels?.map((phaseLevel, index) => (
        <div
          key={index}
          onClick={() => handleLevelClick(index)}
          className={join(
            styles.level,
            phaseLevel.level <= (props.currentLevel ?? 0) && styles.achieved,
            phaseLevel.level === props.currentLevel &&
              props.currentMastery === PhaseMastery.TREATED &&
              styles.treated,
          )}
          style={{
            backgroundImage:
              phaseLevel.level <= (props.currentLevel ?? 0)
                ? `url(${sportBallGraphics[props.phase.sport]})`
                : undefined,
          }}
        />
      ))}
    </FlexElement>
  );
}

export { UserPhaseLevelSelector };
