import { PhaseColor, Sport } from '@hulanbv/toftennis';

const phaseColorLevelBackgroundColors: Record<
  Sport,
  Partial<Record<PhaseColor, [string, string, string]>>
> = {
  [Sport.TENNIS]: {
    [PhaseColor.RED]: ['#e94b52', '#d7101a', '#f3981a'],
    [PhaseColor.ORANGE]: ['#f29f1e', '#ef8218', '#14983f'],
    [PhaseColor.GREEN]: ['#8ebd47', '#4bad4b', '#f8b82a'],
  },
  [Sport.PADEL]: {
    [PhaseColor.RED]: ['#5BBDF0', '#108CDC', '#8CC588'],
    [PhaseColor.ORANGE]: ['#FAF284', '#F6E80B', '#3E5497'],
    [PhaseColor.GREEN]: ['#133870', '#1E5793', '#E3D923'],
  },
  [Sport.WHEELCHAIR_TENNIS]: {
    [PhaseColor.RED]: ['#e94b52', '#d7101a', '#f3981a'],
    [PhaseColor.ORANGE]: ['#f29f1e', '#ef8218', '#14983f'],
  },
};

export { phaseColorLevelBackgroundColors };
