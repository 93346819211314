import { PhaseColor, Sport } from '@hulanbv/toftennis';
import { useEffect, useState } from 'react';
import { join } from '../../../domain/common/utilities/join.utility';
import { FlexElement } from '../flex-element/flex-element.component';
import styles from './phase-color-selector-element.module.css';
import { phaseColorLevelBackgroundColors } from '../../../domain/user-phases/constants/phase-color-level-background-colors.constant';
import { swirlBackgrounds } from '../../../domain/common/constants/swirl-backgrounds.constant';

type Props = {
  selectedSport: Sport;
  defaultValue?: PhaseColor;
  onChange?: (color: PhaseColor) => Promise<void> | void;
};

function PhaseColorSelector(props: Props): JSX.Element {
  const [value, setValue] = useState<PhaseColor>(
    props.defaultValue ?? PhaseColor.GREEN,
  );

  useEffect(() => {
    if (
      props.defaultValue !== undefined &&
      !swirlBackgrounds[props.selectedSport][props.defaultValue]
    ) {
      handleOnColorChange(PhaseColor.GREEN);
    }
  }, [props.selectedSport, props.defaultValue]);

  const handleOnColorChange = (color: PhaseColor) => {
    setValue(color);
    props.onChange?.(color);
  };

  return (
    <FlexElement flex={0} direction="row" gap={15}>
      {Object.keys(swirlBackgrounds[props.selectedSport]).map((color) => (
        <div
          key={color}
          onClick={() => handleOnColorChange(+color)}
          className={join(
            styles.phaseColorItem,
            +color === value && styles.selected,
          )}
          style={{
            backgroundColor:
              phaseColorLevelBackgroundColors[props.selectedSport][
                +color as PhaseColor
              ]?.[1],
          }}
        ></div>
      ))}
    </FlexElement>
  );
}

export { PhaseColorSelector };
